<template>
	<v-sheet>
	<h2 class="mb-8 ig-header">Ihre Funktionen auf einen Blick:</h2>
	<v-row>
		<v-col cols="2" class="text-right">
			<v-btn small color="primary">{{mwtr.LaunchPolls}}</v-btn>
		</v-col>
		<v-col cols="10">	
			<h4 class="ig-header">Mini- Umfrage verwalten</h4>
			<p>Wenn Sie sich unsicher bei der Beantwortung der Fragen eines Digitalisierungsziels sind, haben Sie die Möglichkeit unter „Mini-Umfragen verwalten“ in Kollaboration die Fragen gemeinsam zu beantworten. Gehen Sie auf das jeweilige Digitalisierungsziel und wählen Sie „Mini-Umfragen verwalten“ aus. Anschließend haben Sie die Möglichkeit auf „Neue Mini-Umfrage“ beliebig viele Mini-Umfragen hinzuzufügen. Hierzu können Sie für jede Umfrage, einen individuellen Namen festlegen, den Sie im Feld „Titel“ hinterlegen. Kopieren Sie anschließend den Link und schicken Sie diesen an die ausgewählte Person. Hat die Person die Fragen zu dem spezifischen Ziel beantwortet, werden Ihnen die Antworten entsprechend markiert und Sie können selbst entscheiden, welche Antwort Sie wählen.</p>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="2" class="text-right">
			<v-btn small text>{{mwtr.NewGoalNavItem}}</v-btn>
		</v-col>
		<v-col cols="10">	
			<h4 class="ig-header">Neue Ziele hinzufügen </h4>
			<p>Fallen Ihnen neben den bereits aufgelisteten Digitalisierungszielen noch weitere Ziele ein, die relevant für Ihre Fachabteilung sind? Dann haben Sie die Möglichkeit in der linken Navigationsleiste am Ende jeder Zieldimension neue Ziele hinzuzufügen. Hier können Sie entweder aus dem bestehenden inngo-Inventar Ziele auswählen oder neue individuelle Ziele hinzufügen. Sie haben außerdem die Wahl eine Beschreibung für neue Ziele hinzuzufügen, um dem Geschäftsbereich IT das neue Ziel präziser zu erläutern.</p>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="2" class="text-right">
			<v-avatar color="primary">
				<img style="width: 24px; margin-top: -5px;" src="../assets/img/chat.svg" alt="example chat icon"/>
			</v-avatar>
		</v-col>
		<v-col cols="10">	
			<h4 class="ig-header">Live Chat bedienen</h4> 
			<p>
			Rechts unten auf dem Nachrichten Symbol stehen wir (das inngo Team) Ihnen an Wochentagen von 9.00 bis 17.00 Uhr bei Fragen zur Verfügung.</p>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="2" class="text-right">
			<v-icon color="secondary" large>mdi-check-circle</v-icon>
		</v-col>
		<v-col cols="10">	
			<h4 class="ig-header">
			Erhebung an den Geschäftsbereich IT einreichen
			</h4>
			<p>
			Nachdem Sie Ihre Erhebung mit 100% abgeschlossen haben, können Sie diese bei dem Geschäftsbereich IT auf dem Haken vor Ihrer Fachabteilung und einer finalen Bestätigung, zum GB IT einsenden. Wenn Sie alle Fragen beantwortet haben, wird Ihnen außerdem am Ende der Befragung ein Button mit "Abschließen" zur Verfügung gestellt, auf dem Sie alternativ Ihre Umfrage final einreichen können. 
			</p>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="2" class="text-right">
			<v-btn class="mb-1" color="igblack" dark>{{mwtr.Logout}}</v-btn>
		</v-col>
		<v-col cols="10">	
			<h4 class="ig-header">	
			Ergebnisse zwischenspeichern
			</h4>
			<p>
			Sie können jederzeit Ihren aktuellen Bewertungsstand speichern und sich zu einem beliebigen Zeitpunkt wieder einloggen, um dort weiterzumachen, wo Sie aufgehört haben.
			</p>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="2" class="text-right">
			<v-btn color="primary" x-small class="mr-2">{{mwtr.SaveButton}}</v-btn>
			<v-btn color="primary" x-small class="mr-2">{{mwtr.NextButton}}</v-btn>
		</v-col>
		<v-col cols="10">	
			<h4 class="ig-header">
			Bedeutung von "Weiter" und "Speichern"
			</h4>
			<p>
			Unter jeder Bewertung eines Digitalisierungszieles können Sie Ihre Antworten auf die jeweilige Frage speichern. Hierzu haben Sie zwei Möglichkeiten: Auf "Weiter" können Sie die Antworten speichern und direkt zur nächsten Frage gelangen. Unter "Speichern" speichern Sie Ihre Antworten und verweilen auf dem aktuellen Digitalisierungsziel. 
			</p>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="2" class="text-right">
			<v-chip color="green" class="white--text">100%</v-chip><br/>
			<v-chip class="mt-1">0%</v-chip>
		</v-col>
		<v-col cols="10">	
			<h4 class="ig-header">	
			Bedeutung der %-Angaben bei Zieldimensionen und Zielen 
			</h4>
			<p>
			Die %-Angaben neben den einzelnen Digitalisierungszielen und Zieldimensionen, geben an, zu welchem Grad Sie diese beantwortet haben. %-Angaben der einzelnen Ziele aggregieren sich jeweils hoch zu einer Gesamtprozentzahl von der zugehörigen Zieldimension. Am Ende der Befragung sollten somit alle Zieldimensionen einen Erreichungsgrad von 100% aufweisen, um die Ergebnisse einzusenden.
			</p>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="2" class="text-right">
			<v-btn class="mb-1" text>{{mwtr.ResultsNavItem}}</v-btn>
			<v-btn color="primary">{{mwtr.Download}}</v-btn>
		</v-col>
		<v-col cols="10">	
			<h4 class="ig-header">
			Ergebnisse einsehen und downloaden
			</h4>
			<p>
			In der Navigationsleiste finden Sie neben "Erhebung", auch noch die Option "Ergebnisse". Unter diesem Punkt erhalten Sie einen Überblick über Ihre Antworten. Die Ergebnisse können Sie unter dem Button "Download" im Excel-Tabellen-Format downloaden, um Ihre Ergebnisse jederzeit einzusehen.
			</p>
		</v-col>
	</v-row>

	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "Help", 
		props: {
			
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"
